
import { defineComponent } from 'vue';
import ConditionsOfThisAuthority from '@/components/terms-and-conditions/conditions-of-this-authority/ConditionsOfThisAuthority.vue';
import SpecificConditions from '@/components/terms-and-conditions/specific-conditions/SpecificConditions.vue';
import SummaryCard from '@/components/summary-card/SummaryCard.vue';
import { SingleChoiceQuestion } from "@/interfaces/singlechoicequestionitem/SingleChoiceQuestion";
import { mapActions, mapState } from 'pinia';
import { confirmationStore } from '@/stores/ConfirmationUAPCStore';
import { popupStore } from '@/stores/PopupStore';
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";
import { ConsentQuestion } from '@/interfaces/ConsentQuestion';
import InsuranceClient from '@/rest-client/InsuranceClient';
import { InsurancePolicy } from '@/interfaces/InsurancePolicy';
import PaymentDetailsCard from "@/components/tabs/debit-payment-tab/PaymentDetailsCard.vue";
import { PolicyBenefitPremium } from '@/interfaces/payment/PolicyBenefit';
import { insuranceStore } from "@/stores/InsuranceStore";

export default defineComponent({
  name: "DDConfirmationTab",
  components: {
    ConditionsOfThisAuthority,
    SpecificConditions,
    SummaryCard,
    PaymentDetailsCard,
    LoadingSpinnerWithBackground
  },
  props: {
    isMobileScreen: {type: Boolean, default: false}
  },
  data() {
    return {
      errorMsg: "",
      consent: [] as ConsentQuestion[],
      isLoading: true,
      validationError: false,
      directDebitItems: [] as SingleChoiceQuestion[],
      paymentDetailsItems: [] as SingleChoiceQuestion[]
    }
  },
  computed: {
    ...mapState(confirmationStore, ['policyInfo']),
    nextPath(): string {
      return this.getNextPage(this.$route.fullPath);
    },
    premiumAmount() {
      // Filter for premiums that include renewal date - if date is undefined it is a pro-rata premium
      const policyBenefitPremiumsExcludingProRata = this.flattenedPolicyBenefitPremiums(this.policyInfo.policy.policyBenefits)
        .filter((policyBenefitPremium: PolicyBenefitPremium) => !!policyBenefitPremium.benefitRenewalDate);

      // Sum the premium amount from all valid policy benefits
      const paymentAmount = policyBenefitPremiumsExcludingProRata.reduce(
        (total: number, current: PolicyBenefitPremium) => total + current.premiumAmount, 0);

      return paymentAmount;
    },
    proRata() {
      // Filter for premiums that include renewal date - if date is undefined it is a pro-rata premium
      const policyBenefitPremiumsOnlyProRata = this.flattenedPolicyBenefitPremiums(this.policyInfo.policy.policyBenefits)
        .filter((policyBenefitPremium: PolicyBenefitPremium) => !policyBenefitPremium.benefitRenewalDate);

      // Sum the premium amount from all valid policy benefits
      const proRataAmount = policyBenefitPremiumsOnlyProRata.reduce(
        (total: number, current: PolicyBenefitPremium) => total + current.premiumAmount, 0);

      return proRataAmount;
    },
    shouldShowPaymentDetailsCard(): boolean {
      const payment = this.policyInfo?.policy?.paymentConfigurations[0];

      return this.showPaymentDetailsCard(
        payment.frequency,
        this.premiumAmount,
        this.policyInfo.policy.policyType,
        this.policyInfo.policy.firstPremiumDate,
        this.policyInfo.policy.nextPremiumDate
      );
    }
  },
  async mounted() {
    // Check that the payment method is "Direct Debit" and skip this page if not
    if(this.policyInfo?.policy?.paymentConfigurations) {
      const paymentConfiguration = this.policyInfo.policy.paymentConfigurations[0];

      if(paymentConfiguration?.method?.toLowerCase() !== "Direct Debit".toLowerCase()) {
        this.updateConfirmationItemCheckedStatus("InsuranceWebUAPCDirectDebit");
        if(this.nextPath) {
          this.$router.push(this.nextPath);
          return;
        } // TODO: what happens if the nextpath is null or empty?
      }

      this.consent = await this.getConsentQuestions('InsuranceWebUAPCDirectDebit');

      try {
        this.checkStoreToLoadPage();
      } catch (e) {
        this.isLoading = true;
        const popupBox = popupStore();
        if (e instanceof Error) {
          popupBox.showErrorMsg(e.message);
        }
        return;
      }

      this.isLoading = false;
      this.directDebitItems = [
        {
          text: "Bank name",
          value: paymentConfiguration?.directDebit?.bankName ?? "",
        },
        {
          text: "Bank account number",
          value: paymentConfiguration?.directDebit?.bankAccountNumber
            ? this.obfuscateBankAccountNumber(paymentConfiguration?.directDebit?.bankAccountNumber)
            : "",
        },
        {
          text: "Bank account name",
          value: paymentConfiguration?.directDebit?.bankAccountName ?? "",
        }
      ];
    }
  },
  methods: {
    ...mapActions(confirmationStore, ['updateConfirmationItemCheckedStatus', 'submitDdQuestionAnswers',
    'checkConfirmationPageStatus', 'getNextPage', 'getCancelledPage', 'previousPagesCheckStatus', 'getConsentQuestions']),
    ...mapActions(insuranceStore, ['flattenedPolicyBenefitPremiums', 'getPremiumAmountTotalByFrequency', 'showPaymentDetailsCard']),
    checkStoreToLoadPage() {
      if (this.checkConfirmationPageStatus("InsuranceWebUAPCDirectDebit", this.consent)) {
        if(this.nextPath) {
          this.$router.push(this.nextPath);
        }
      } else if(!this.previousPagesCheckStatus("InsuranceWebUAPCDirectDebit")) {
        throw new Error('Please complete the previous steps.');
      }
    },
    validate() {
      this.errorMsg = "";
      this.validationError = !this.consent[0].value;
      return !this.validationError;
    },
    async goToNextPage() {
      if (!this.validate()) {
        return;
      }

      this.isLoading = true;
      const response = await this.submitDdQuestionAnswers(this.consent);
      if (response?.statusCode != 200) {
        this.errorMsg = "Error happened while submitting data.";
        this.isLoading = false;
        return;
      }

      this.updateConfirmationItemCheckedStatus("InsuranceWebUAPCDirectDebit");
      if(this.nextPath) {
        this.$router.push(this.nextPath);
      }
    },
    showCancelPaymentPopup() {
      const popupBox = popupStore();
      popupBox.popupState = {
        title: "Cancel payment",
        content: "Are you sure you want to cancel your payment?",
        secondaryText: "No",
        okay: "Yes",
        showPopupBox: true,
        primaryActionBtn: this.cancelPayment
      };
    },
    async cancelPayment() {
      this.policyInfo.policy.paymentConfigurations[0].directDebit = null;
      this.policyInfo.policy.paymentConfigurations[0].method = "Online banking";
      this.policyInfo.policy.paymentConfigurations[0].policyId = this.policyInfo.policy.id;
      let policyDocument = this.toJsonPatchDocument(this.policyInfo.policy);
      let response = await InsuranceClient.updateInsurancePolicy(
        this.policyInfo.policy.id,
        policyDocument,
      );

      if (response?.statusCode != 200) {
        const popupBox = popupStore();
        popupBox.showErrorMsg("Error occurred while cancelling payment.");
        return;
      }

      this.updateConfirmationItemCheckedStatus("InsuranceWebUAPCDirectDebit");
      const nextPath = this.getCancelledPage(this.$route.query, "PaymentCancelled");
      if(nextPath) {
        this.$router.push(nextPath);
      }
    },
    toJsonPatchDocument(policyDocument: InsurancePolicy) {
      let patchDocument = [{
        op: "replace",
        path: "/paymentConfigurations",
        value: policyDocument.paymentConfigurations,
      }];
      return patchDocument;
    },
    obfuscateBankAccountNumber(bankAccountNumber: string): string {
      // Only show the last 7 digits
      // Validate that the string is in the proper bank account format, otherwise just return it
      if (!(/^\d{2}-\d{4}-\d{7}-\d{3}$/.test(bankAccountNumber))) {
        return bankAccountNumber;
      }

      const firstDigits = bankAccountNumber.substring(0, bankAccountNumber.length - 8);
      const lastSevenDigits = bankAccountNumber.substring(bankAccountNumber.length - 8);

      return `${firstDigits.replaceAll(/\d/g, "X")}${lastSevenDigits}`;
    },
    parseDate(dateString: string): string {
      return new Date(dateString).toLocaleDateString();
    },
    parseDollar(dollarAmount: number): string {
      return `$${this.formatCurrency(dollarAmount)}`;
    },
    formatCurrency(num : number): string {
      return num.toFixed(2);
    }
  }
});
