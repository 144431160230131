
import { defineComponent } from "vue";
import { popupStore } from "@/stores/PopupStore";
import { mapActions, mapState } from "pinia";
import { confirmationStore } from "@/stores/ConfirmationUAPCStore";
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";

export default defineComponent({
  name: "PaymentConfirmationTab",
  components: {
    LoadingSpinnerWithBackground,
  },
  props: {
    screenWidth: {type: String, required: true},
    isMobileScreen: {type: Boolean, default: false}
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState(confirmationStore, ["policyInfo"])
  },
  mounted() {
    try {
      this.checkStoreToLoadPage();
    } catch (e) {
      this.isLoading = true;
      const popupBox = popupStore();
      if (e instanceof Error) {
        popupBox.showErrorMsg(e.message);
      }
    }
  },
  methods: {
    ...mapActions(confirmationStore, ['updateConfirmationItemCheckedStatus',
    'checkConfirmationPageStatus', 'previousPagesCheckStatus', 'getNextPage']),
    checkStoreToLoadPage() {
      if (this.checkConfirmationPageStatus("InsuranceWebUAPCPaymentConfirmation")) {
        throw new Error('Payment is already confirmed!');
      } else if (!this.previousPagesCheckStatus("InsuranceWebUAPCPaymentConfirmation")) {
        throw new Error('Please complete the previous steps.');
      }
    },
    getPaymentMethod() {
      if (this.policyInfo.policy.paymentConfigurations && this.policyInfo.policy.paymentConfigurations.length > 0) {
        return this.policyInfo.policy.paymentConfigurations[0]?.method;
      } else {
        return "Other";
      }
    },
    haveSecondaryPolicyOwner() {
      // TODO: Ensure that this logic works with the changes to policy ownership. This logic should work if there are 2 policy owners.
      return this.policyInfo.policy.policyOwners && this.policyInfo.policy.policyOwners.length > 1;
    }
  }
});
