
import InsuranceClient from "@/rest-client/InsuranceClient";
import SingleChoiceQuestionItem from "@/components/single-choice-question-Item/SingleChoiceQuestionItem.vue";
import OnlineBanking from "@/components/debit-payment/OnlineBanking.vue";
import DirectDebitForm from "@/components/debit-payment/DirectDebitForm.vue";
import DirectDebitTemplate from "@/components/debit-payment/DirectDebitTemplate.vue";
import DebitPaymentConfirmation from "@/components/debit-payment/DebitPaymentConfirmation.vue";
import SelectInput from "@/components/select-input/SelectInput.vue";
import DatePicker from "@/components/date-picker/DatePicker.vue";
import PopupBox from "@/components/popup-box/PopupBox.vue";
import { PropType, defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import { InsurancePolicy } from "@/interfaces/InsurancePolicy";
import { Payment } from "@/interfaces/payment/Payment";
import { insuranceStore } from "@/stores/InsuranceStore";
import { routerStore } from "@/stores/RouterStore";
import { PolicyBenefit } from "@/interfaces/payment/PolicyBenefit";
import ProductQuoteDataGenerator from "@/data-generator/ProductQuoteDataGenerator";
import { DirectDebit } from "@/interfaces/payment/DirectDebit";
import { PolicyBenefitStatusRequest } from "@/interfaces/requests/PolicyBenefitStatusRequest"
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";
import { ScreenWidth } from "@/enums/ScreenWidth.enum";
import PaymentDetailsCard from "./PaymentDetailsCard.vue";
import { ConsentAnswer } from "@/interfaces/ConsentAnswer";
import { ConsentQuestion } from "@/interfaces/ConsentQuestion";
import { BenefitStatus } from "@/enums/BenefitStatus.enum";
import RoundingUtility from "@/utils/RoundingUtility";

export default defineComponent({
  name: "DebitPaymentTab",
  components: {
    SingleChoiceQuestionItem,
    OnlineBanking,
    DirectDebitForm,
    SelectInput,
    DatePicker,
    DirectDebitTemplate,
    PopupBox,
    DebitPaymentConfirmation,
    LoadingSpinnerWithBackground,
    PaymentDetailsCard
  },
  props: {
    screenWidth: { type: String as PropType<ScreenWidth>, required: true }
  },
  data() {
    return {
      errorMsg: "",
      loading: true,
      isConfirmationPage: false,
      consentAnswers: [] as ConsentAnswer[],
      frequencies: [
        { value: "Weekly", text: "Weekly" },
        { value: "Fortnightly", text: "Fortnightly" },
        { value: "Monthly", text: "Monthly" },
        { value: "Annually", text: "Yearly" },
      ],
      doesAgree: false,
      payment: new Object() as Payment,
      paymentMethods: [
        { value: "Direct debit", text: "Direct debit" },
        { value: "Online banking", text: "Online banking" },
        { value: "Direct debit form", text: "Direct debit form" },
      ],
      annualAmount: 0,
      clientValidationErrors: [],
      errors: {} as Record<string, string>,
      paymentMethodError: undefined as string | undefined,
      paymentFrequencyError: undefined as string | undefined,
      nextPaymentError: undefined as string | undefined,
      firstPaymentError: undefined as string | undefined,
      previousPath: null as string | null,
      nextPath: null as string | null,
      existingPremiumMonthly: 0,
      proRata: 0,
      isPolicyReferred: false
    };
  },
  computed: {
    ...mapState(insuranceStore, ['policyInfo', 'existingPolicyBenefits']),
    ongoingMaxDate(): Date {
      var today = new Date();
      var daysToAdd = 0;
      if (this.payment.frequency == "Weekly") {
        daysToAdd = 7;
      }
      else if (this.payment.frequency == "Fortnightly") {
        daysToAdd = 14;
      }
      else if (this.payment.frequency == "Monthly") {
        daysToAdd = 30;
      }
      else if (this.payment.frequency == "Annually") {
        daysToAdd = 14;
      }
      return new Date(today.setDate(today.getDate() + daysToAdd));
    },
    firstMaxDate(): Date {
      var today = new Date();
      return new Date(today.setDate(today.getDate() + 14));
    },
    showFirstPremiumPicker(): boolean {
      // Only allow user to change first premium date if they are applying for the first time
      if (this.existingPremiumMonthly > 0) {
        return false;
      }

      if (this.payment.frequency == undefined || this.policyInfo.policy.nextPremiumDate == undefined) {
        return false;
      }

      var notWithinDateRange = this.getDifferenceBetweenTwoDates(new Date().toDateString(), this.policyInfo.policy.nextPremiumDate) > 14;
      var isCorrectFrequency = this.payment.frequency == 'Monthly';
      return isCorrectFrequency && notWithinDateRange;
    },
    isMobileScreen() {
      return this.screenWidth === ScreenWidth.Mobile;
    },
    hasProRata(): boolean {
      return this.payment.frequency != "Annually"
        && this.policyInfo.policy.nextPremiumDate != null
        && new Date(this.policyInfo.policy.nextPremiumDate).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
    },
    existingPolicyBenefitPremiums() {
      return this.flattenedPolicyBenefitPremiums(this.existingPolicyBenefits);
    },
    premiumAmount() {
      const policyBenefitPremiums = this.flattenedPolicyBenefitPremiums(this.policyInfo.policy.policyBenefits);
      const premiumAmountSumByFrequency = this.getPremiumAmountTotalByFrequency(policyBenefitPremiums, this.payment.frequency);

      const existingPremiumAmountSumByFrequency = this.getPremiumAmountTotalByFrequency(this.existingPolicyBenefitPremiums, this.payment.frequency);

      return premiumAmountSumByFrequency + existingPremiumAmountSumByFrequency;
    },
    shouldShowPaymentDetailsCard(): boolean {
      return this.showPaymentDetailsCard(
        this.payment.frequency,
        this.premiumAmount,
        this.policyInfo.policy.policyType,
        this.policyInfo.policy.firstPremiumDate,
        this.policyInfo.policy.nextPremiumDate
      );
    }
  },
  watch: {
    async 'policyInfo.policy.nextPremiumDate' (val: string) {
      // When first premium is not explictly selected, next premium date is the first premium date
      if (!this.showFirstPremiumPicker) {
        this.policyInfo.policy.firstPremiumDate = val;
      }

      this.proRata = await this.calculateProRata();
    },
    async 'payment.frequency' () {
      this.proRata = await this.calculateProRata();
    },
    showFirstPremiumPicker(val: boolean) {
      if (val) {
        this.policyInfo.policy.firstPremiumDate = "";
      }
      else if (!val) {
        this.policyInfo.policy.firstPremiumDate = this.policyInfo.policy.nextPremiumDate;
      }
    }
  },
  async mounted() {
    let dataGenerator = new ProductQuoteDataGenerator();
    let policyData = await dataGenerator.getPolicySchedule();
    this.isPolicyReferred = this.checkPolicyReferred(policyData);
  },
  created() {
    this.payment =
      this.policyInfo.policy.paymentConfigurations &&
        this.policyInfo.policy.paymentConfigurations.length > 0
        ? this.policyInfo.policy.paymentConfigurations[0]
        : (new Object() as Payment);

    this.payment.policyId = this.policyInfo.policy.id;
    
    let amount = 0;

    this.policyInfo.policy.policyBenefits.forEach(
      (policyBenefit: PolicyBenefit) => {
        if (
          !policyBenefit.policyBenefitPremiums ||
          policyBenefit.policyBenefitPremiums.length <= 0
        ) {
          return;
        }

        amount =
          amount +
          policyBenefit.policyBenefitPremiums
            .map((a: { premiumAmount: number }) => RoundingUtility.round(a.premiumAmount))
            .reduce((a: number, b: number) => {
              return a + b;
            }) *
          12;
      },
    );
    this.annualAmount = amount;

    // Get existing premium as a monthly payment
    // TODO: does this need to be in the initial function or can it just be a calculated function?
    if (this.existingPolicyBenefits && this.existingPolicyBenefits.length > 0) {
      this.existingPremiumMonthly = this.getPremiumAmountTotalByFrequency(this.existingPolicyBenefitPremiums, 'Monthly');
    }

    this.nextPath = this.getPage(1, this.$route.path, this.$route.query);
    this.previousPath = this.getPage(-1, this.$route.path, this.$route.query);

    this.loading = false;
  },
  methods: {
    ...mapActions(insuranceStore, ['flattenedPolicyBenefitPremiums', 'getPremiumAmountTotalByFrequency', 'mapFluentValidationErrors', 'showPaymentDetailsCard']),
    ...mapActions(routerStore, ['getPage']),
    async submitPolicy() {
      if (!this.doesAgree && this.payment.method == "Direct debit") {
        this.errors["debitPaymentConfirmation"] = "Confirmation and acceptance required";
        return;
      }

      if (!this.validatePayment()) {
        return;
      }

      if (this.loading || this.policyInfo.policy == undefined) {
        return;
      }

      this.loading = true;

      this.policyInfo.policy.paymentConfigurations = [this.payment];

      if (this.payment.method != "Direct debit") {
        this.payment.directDebit = null;
      } else if (this.policyInfo.policy.policyType == "1" && this.payment.directDebit) {
        const insuredPerson = this.policyInfo.insuredPerson;
        this.payment.directDebit.email = insuredPerson.email;
        this.payment.directDebit.contactNumber = insuredPerson.mobile;
      } else if (this.consentAnswers[0]?.value === 'Yes' && this.payment.directDebit) {
        const insuredPerson = this.policyInfo.insuredPerson;
        this.payment.directDebit.email = insuredPerson.email;
        this.payment.directDebit.contactNumber = insuredPerson.mobile ?? insuredPerson?.workPhone ?? "";
        this.payment.directDebit.fullName = insuredPerson.middleName ?
          `${insuredPerson.firstName} ${insuredPerson.middleName} ${insuredPerson.lastName}` :
          `${insuredPerson.firstName} ${insuredPerson.lastName}`;
      }

      if (this.payment.method == "Direct debit" && this.policyInfo.policy.policyType == "0") {
        const response = await InsuranceClient.submitConsentQuestionAnswers(this.consentAnswers);
        if (response?.statusCode != 200) {
          this.errorMsg = "Error happened while submitting data.";
          if (response?.statusCode == 400) {
            const mappedErrors = this.mapFluentValidationErrors(
              response.body?.errors,
            );
            this.set400Errors(mappedErrors);
            this.errorMsg += " Please check submitted data.";
          }
          this.loading = false;
          return;
        }
      }

      let policyDocument = this.toJsonPatchDocument(this.policyInfo.policy);
      let response = await InsuranceClient.updateInsurancePolicy(
        this.policyInfo.policy.id,
        policyDocument,
      );
      if (response?.statusCode != 200) {
        if (response?.statusCode == 400) {
                    const mappedErrors = this.mapFluentValidationErrors(
            response.body?.errors,
          );
          this.set400Errors(mappedErrors);
          if (this.showBlueBannerError()) {
            this.errorMsg = "Error happened while submitting data. Please check submitted data.";
          }
          // SLI - Display the previous page (enter debit details) if there are any api validation errors
          this.isConfirmationPage = false;
        } else if (
          this.policyInfo.policy.paymentConfigurations[0].method ==
          "Direct debit"
        ) {
          this.errorMsg = "Error happened while submitting data. Please check submitted data.";
        }
        this.loading = false;

        return;
      }

      // For referred cases, go to the end page directly
      if (!this.policyInfo.policyBenefits.find(pb => pb.benefitStatus == BenefitStatus.Initialized))
      {
        this.loading = false;

        if (this.nextPath) {
          this.$router.push(this.nextPath);
        }
        return;
      }

      // If has pro rata, create seperate premiums for them
      if (this.hasProRata) {
        let policyBenefitRequest = {
          gender: this.policyInfo.insuredPerson.gender,
          isSmoker: this.policyInfo.insuredPerson.smokerStatus === "Smoker",
          policyBenefits: this.policyInfo.policy.policyBenefits,
          nextPremiumDate: this.policyInfo.policy.nextPremiumDate
        };
        let response = await InsuranceClient.submitPolicyBenefits(policyBenefitRequest);
        if (response?.statusCode != 200) {
          this.errorMsg = "Unable to update Policy Benefit.";
          this.loading = false;
          return;
        }
      }

      // If user is applying for comprehensive we have to wait for them to confirm
      // An email will be sent with a link that the user needs to click
      if (this.policyInfo.policy.policyType == "0" && !this.isPolicyReferred) {
        let acceptanceEmailResponse = await InsuranceClient.sendAcceptanceEmail();

        if (acceptanceEmailResponse.statusCode != 200) {
          this.errorMsg = "Error happened while submitting data.";
          this.loading = false;
          return
        }

        let policyBenefitStatusRequest = {} as PolicyBenefitStatusRequest;
        policyBenefitStatusRequest.policyBenefits = this.policyInfo.policy.policyBenefits.filter(x => x.benefitStatus == BenefitStatus.Initialized).map((policyBenefit: PolicyBenefit) => {
          return {
            id: policyBenefit.id,
            benefitStatus: BenefitStatus.WaitingForConfirmation
          }
        })

        if (policyBenefitStatusRequest.policyBenefits.length > 0) {
          let policyBenefitStatusResponse = await InsuranceClient.updatePolicyBenefitsStatus(policyBenefitStatusRequest);

          if (policyBenefitStatusResponse.statusCode != 200) {
            this.errorMsg = "Error happened while submitting data.";
            this.loading = false;
            return;
          }
        }
      }

      if (this.policyInfo.policy.policyType == "1") {
                let memberNumberResponse = await InsuranceClient.updateCustomerMemberNumber();
        if (memberNumberResponse.statusCode != 200) {
                    this.errorMsg = "Error happened while submitting data.";
            this.loading = false;
            return;
        }
      }

      if (this.nextPath) {
        this.$router.push(this.nextPath);
      }
    },
    checkPolicyReferred(policyData: any) {

      let isReferred = false;
      if (this.policyInfo.policy.policyType == "1")
      {
         isReferred = ProductQuoteDataGenerator.referredStatusListSSLI.includes(policyData.policyStatusName);
      }
      else
      {
        isReferred = ProductQuoteDataGenerator.referredStatusListCLI.includes(policyData.policyStatusName);
      }

      if (!isReferred) {
        isReferred = this.policyInfo.policy.policyBenefits.some(policyBenefit => policyBenefit.quircPolicy.note?.length > 0);
      }

      return isReferred;
    },
    toJsonPatchDocument(policyDocument: InsurancePolicy) {
      let patchDocument = [];
      patchDocument.push({
        op: "replace",
        path: "/paymentConfigurations",
        value: policyDocument.paymentConfigurations,
      });
      patchDocument.push({
        op: "replace",
        path: "/nextPremiumDate",
        value: policyDocument.nextPremiumDate,
      });
      patchDocument.push({
        op: "replace",
        path: "/firstPremiumDate",
        value: policyDocument.firstPremiumDate,
      });
      return patchDocument;
    },
    toNextPage() {
      if (this.payment.method == "Direct debit") {
        if (!this.validatePayment()) {
          return;
        }
        if (this.policyInfo.policy.policyType == "1") {
          this.isConfirmationPage = true;
          // Reset the error messages
          this.errorMsg = "";
        }
        else {
          this.doesAgree = true;
          this.submitPolicy();
        }
      }
      else {
        this.submitPolicy();
      }
    },
    set400Errors(mappedErrors: Record<string, any> | undefined) {
      if (!mappedErrors || !mappedErrors["paymentConfigurations[0]"]) {
        return;
      }

      const paymentErrors = mappedErrors["paymentConfigurations[0]"];
      this.paymentFrequencyError = mappedErrors["frequency"];
      this.paymentMethodError = paymentErrors["method"];
      this.errors = paymentErrors["directDebit"];
    },
    validatePayment() {
      let valid = true;
      this.paymentMethodError = undefined;
      this.paymentFrequencyError = undefined;
      this.nextPaymentError = undefined;
      this.firstPaymentError = undefined;
      this.errors = {};

      if (this.payment.method == null) {
        this.paymentMethodError = "Answer required";
        valid = false;
      }

      if (this.payment.frequency == null) {
        this.paymentFrequencyError = "Answer required";
        valid = false;
      }

      if (this.payment.method == "Direct debit") {
        if (!this.payment.directDebit) {
          this.payment.directDebit = (
            this.$refs.directDebitTemplateComponent as any
          ).getNewDirectDebit();
        }

        let skipKeyList = ["id", "policyMemberNumber"];
        if (this.policyInfo.policy.policyType == "0") {
          if (!this.consentAnswers[0]?.value) {
            this.errors["consentQuestions[0]"] = "Answer required";
            valid = false;
          }
          if (this.consentAnswers[0]?.value === 'No' && !this.consentAnswers[1]?.value) {
            this.errors["consentQuestions[1]"] = "Answer required";
            valid = false;
          }
          if (this.consentAnswers[0]?.value === 'No' && this.consentAnswers[1]?.value === 'No') {
            valid = false;
          }

          // Ignore validations for bank account holder details in case policy owner has full authority for paying premiums.
          if (this.consentAnswers[0]?.value === "Yes") {
            skipKeyList.push("fullName");
            skipKeyList.push("email");
            skipKeyList.push("contactNumber");
          }
        }
        else {
          // Skip these fields as they aren't visible in ssli
          skipKeyList.push("email");
          skipKeyList.push("contactNumber");
        }

        let key: keyof DirectDebit;
        for (key in this.payment.directDebit) {
          if (skipKeyList.includes(key)) {
            continue;
          }

          let value = this.payment.directDebit![key];

          if (!value || value.length <= 0) {
            this.errors[key] = "Answer required";
            valid = false;
          }

          if (key === "bankAccountNumber" && value) {
            if (/^\d{2}-\d{4}-\d{7}-\d{2}$/.test(value)) {
              this.errors[key] = "Answer requires 3 digits in suffix";
              valid = false;
            }
            else if (!(/^\d{2}-\d{4}-\d{7}-\d{3}$/.test(value))) {
              this.errors[key] = "Answer is not in the correct format";
              valid = false;
            }
          }

          if (key === "email" && value) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
              this.errors[key] = "Invalid email address";
              valid = false;
            }
          }
        }
      }

      // Comprehensive validation errors
      var policy = this.policyInfo.policy;
      if (policy.policyType == "0") {
        if (policy.nextPremiumDate == null) {
          this.nextPaymentError = "Answer required";
          valid = false;
        }

        if (this.showFirstPremiumPicker && policy.firstPremiumDate == null) {
          this.firstPaymentError = "Answer required";
          valid = false;
        }
      }
      return valid;
    },
    showBlueBannerError() {
      if(this.policyInfo.policy.paymentConfigurations[0].method == "Direct debit") {
        
        // Check if there are any errors other than 'bankAccountNumber'
        var otherErrors = Object.keys(this.errors).filter(key => key !== 'bankAccountNumber');

        return (this.paymentFrequencyError !== undefined || this.paymentMethodError !== undefined)
          && otherErrors.length > 0;
      }
        return true;
    },
    getDifferenceBetweenTwoDates(dateString1: string, dateString2: string) {
      var date1 = new Date(new Date(dateString1).setHours(0, 0, 0, 0));
      var date2 = new Date(new Date(dateString2).setHours(0, 0, 0, 0));
      var differenceInTime = date2.getTime() - date1.getTime();

      var differenceInDays = differenceInTime / (1000 * 3600 * 24);

      return differenceInDays;
    },
    setConsentAnswers(consentQuestions: ConsentQuestion[]) {
      this.consentAnswers = [
          {
            consentQuestionId: consentQuestions[0]?.questionId,
            value: consentQuestions[0]?.value
          }
      ];
      if (consentQuestions[0]?.value === "No") {
        this.consentAnswers.push({
          consentQuestionId: consentQuestions[1]?.questionId,
          value: consentQuestions[1]?.value
        })
      }
    },
    async calculateProRata() {
      var proRata = 0;
      if (this.hasProRata) {
        for (let policyBenefit of this.policyInfo.policy.policyBenefits) {
          const request = {
            gender: this.policyInfo.insuredPerson.gender,
            isSmoker: this.policyInfo.insuredPerson.smokerStatus === "Smoker",
            benefitCode: policyBenefit.benefit.benefitCode,
            coverAmount: policyBenefit.coverAmount,
            nextPremiumDate: this.policyInfo.policy.nextPremiumDate,
            policyRefId: this.policyInfo.policyRefId
          };
          const benefitPremiumResponse = await InsuranceClient.calculateBenefitPremiums(request);
          proRata += RoundingUtility.round(benefitPremiumResponse.body.monthlyPremium);
        }
      }
      return RoundingUtility.round(proRata);
    }
  },
});
