
import { defineComponent } from "vue";
import InsuranceClient from "@/rest-client/InsuranceClient";
import QuestionnaireAnswerMappingService from "@/services/QuestionnaireAnswerMappingService";
import FileUtility from "@/common/FileUtility";
import { popupStore } from "@/stores/PopupStore";
import { mapActions, mapState } from "pinia";
import { confirmationStore } from "@/stores/ConfirmationUAPCStore";
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";
import QuestionnaireAnswerSectionComponent from "@/components/questionnaire-answer-section/QuestionnaireAnswerSection.vue";
import { ConsentQuestion } from "@/interfaces/ConsentQuestion";
import { QuestionnaireAnswerSection } from "@/interfaces/QuestionnaireAnswer";
import { ScreenWidth } from '@/enums/ScreenWidth.enum';
import { dataGeneratorConfigs } from '@/data-generator/DataGeneratorConfigs';
import UnacceptedApplicationResult from "@/components/result-information/UnacceptedApplicationResult.vue";

export default defineComponent({
  name: "CoverAcceptanceTab",
  components: {
    LoadingSpinnerWithBackground,
    QuestionnaireAnswerSectionComponent,
    UnacceptedApplicationResult,
  },
  props: {
    screenWidth: {type: String, required: true}
  },
  data() {
    return {
      consentQuestions: [] as ConsentQuestion[],
      questionnaireAnswers: [] as QuestionnaireAnswerSection[],
      yourQuoteSection: {} as QuestionnaireAnswerSection,
      validationError: '',
      errorMsg: null as string | null,
      isLoading: false,
      link: '',
      hrefLink: '',
      showApplicationNotAcceptedResult: false
    }
  },
  computed: {
    ...mapState(confirmationStore, ['policyInfo']),
    isMobileScreen() {
      return [ScreenWidth.Mobile, ScreenWidth.Small].includes(this.screenWidth as ScreenWidth);
    }
  },
  async mounted() {
    let documentTypes: { documentType: string, documentLink: string }[] = [];
    let policyDoclink = await this.getDownloadLink('InsPolicyDoc','CLI', 'Policy Document', 'Booster SmartCover Policy Document BSC001072024.pdf');
    documentTypes.push({ "documentType": "{Policy Document}", "documentLink": policyDoclink });

    let offerLetterlink = await this.getDownloadLink('InsCliOfferLetter','CLI', 'Offer of Insurance', 'OfferLetter.pdf');
    documentTypes.push({ "documentType": "{Offer of Insurance}", "documentLink": offerLetterlink });

    this.consentQuestions = await this.replaceWordWithLink('InsuranceWebUAPCAcceptanceCover',documentTypes);
    await this.loadQuestionnaireAnswers();

    try {
      this.checkStoreToLoadPage();
    } catch (e) {
      this.isLoading = true;
      const popupBox = popupStore();
      if (e instanceof Error) {
        popupBox.showErrorMsg(e.message);
      }
    }
  },
  methods: {
    ...mapActions(confirmationStore, [
      'applicationCancel',
      'replaceWordWithLink',
      'checkConfirmationPageStatus',
      'getCancelledPage',
      'getConsentQuestions',
      'getNextPage',
      'getQuestionnaireAnswers',
      'getYourQuoteDetails',
      'previousPagesCheckStatus',
      'submitConsentQuestionAnswers',
      'updateConfirmationItemCheckedStatus',
    ]),
    checkStoreToLoadPage() {
      if (this.checkConfirmationPageStatus("InsuranceWebUAPCAcceptanceCover", this.consentQuestions)) {
        const nextPath = this.getNextPage(this.$route.fullPath);
        if(nextPath) {
          this.$router.push(nextPath);
        }
      } else if(!this.previousPagesCheckStatus("InsuranceWebUAPCAcceptanceCover")) {
        throw new Error('Please complete the previous steps.');
      }
    },
    async getDownloadLink(documentType: string, productType: string, linkContent: string, fileName: string) {
      const response = await InsuranceClient.generateDocumentlink(documentType, productType);
      var hrefCode = "";
      if (response?.statusCode == 200 || response?.statusCode == 204) {
        const link = response.body.link;
        hrefCode =`<a style='color:#007abc;text-decoration: underline;cursor:pointer' id='policyDocument' filename='${fileName}' link='${link}'>${linkContent}</a>`;
      }

      return hrefCode;
    },
    async downloadDocument(event: any)
    {
      let link = event.target.getAttribute("link");
      let fileName = event.target.getAttribute("filename");

      if (!link || link.length <= 0) {
        return;
      }

      const fileResponse=await InsuranceClient.getDocumentFromLink(link);
      if (fileResponse?.statusCode == 200 || fileResponse?.statusCode == 204)
      {
        await FileUtility.downloadFile(fileResponse.body.content, fileName);
      }
    },
    validate() {
      this.errorMsg = null;
      this.validationError = '';

      if (!this.consentQuestions[0].value || !this.consentQuestions[1].value) {
        this.validationError = "Confirmation is required.";
      }

      return !this.validationError? true: false;
    },
    async loadQuestionnaireAnswers() {
      this.isLoading = true;
      const aboutYouAndMedicalSections = await this.getQuestionnaireAnswers();
      this.isLoading = false;

      const mappedAboutYouAndMedicalSections = await QuestionnaireAnswerMappingService.mapQuestionnaireAnswers(aboutYouAndMedicalSections, dataGeneratorConfigs);
      this.yourQuoteSection = this.getYourQuoteDetails();

      // Prepend the "Your quote" section
      this.questionnaireAnswers = [this.yourQuoteSection, ...mappedAboutYouAndMedicalSections];
    },
    async goToNextPage() {
      if (!this.validate()) {
        return;
      }

      this.isLoading = true;
      const resp = await this.submitConsentQuestionAnswers(this.consentQuestions);
      if (resp?.statusCode != 200) {
        this.errorMsg = "Error happened while submitting data.";
        this.isLoading = false;
        return;
      }

      const response = await InsuranceClient.updateCustomerMemberNumber();
      if (response?.statusCode != 200 && response?.statusCode != 204) {
        this.errorMsg = 'Error happened while submitting data.';
        if (response?.statusCode == 400) {
          this.errorMsg += ' Please check submitted data.'
        }

        this.isLoading = false;
        const popupBox = popupStore();
        popupBox.showErrorMsg(this.errorMsg);
        return;
      } else {
        this.updateConfirmationItemCheckedStatus("InsuranceWebUAPCAcceptanceCover");
        const nextPath = this.getNextPage(this.$route.fullPath);
        if(nextPath) {
          this.$router.push(nextPath);
        }
      }
    },
    cancelApplicationPopup() {
      const popupBox = popupStore();
      popupBox.popupState = {
        title: "I don't accept",
        content: "Are you sure you don't want to accept your cover?",
        secondaryText: "No",
        okay: "Yes",
        showPopupBox: true,
        primaryActionBtn: this.cancelApplication
      };
    },
    async cancelApplication() {
      const response = await this.applicationCancel();
      if (!response) {
        const popupBox = popupStore();
        popupBox.showErrorMsg("Error occurred while cancelling application.");
        return;
      }

      this.showApplicationNotAcceptedResult = true;
    }
  }
});
